<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true,right:'说明',real:false}"></v-header>
        </div>
        <div class="paylist">
            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="left">
                    <span>{{item.leftText}}</span>
                    <input v-if="item.inputVal" @change="getEgg(index)" v-model="val" placeholder="0" type="number">
                    <img src="../../assets/images/egg.png" alt="">
                </div>
                <div class="right">
                    <span ref="num">{{item.rightVal}}</span>
                    <button @click="pay()">充值</button>
                </div>
            </div>
        </div>
        <div class="proxy">
            <input type="checkbox" checked name="" id="">
            <span>我已阅读并同意<span class="gotoProxy" @click="goPage('payProxy')">《充值服务协议》</span></span>
        </div>
        <div class="present">
            <p @click="goPage('masterPay')">大师包月优惠大礼包 <span class="arrow"></span></p>
        </div>
        <div class="desc">
            <p>【注】：</p>
            <p>1、太阳为好劲抽七星彩专属虚拟货币，1元=1个太阳</p>
            <p>2、太阳可用于查看大师预测，购买会员，打赏别人</p>
            <p>3、充值的太阳不可以兑换充值卡</p>
            <p>4、太阳不可以提起或退款</p>
            <p>5、如有疑问，请联系客服</p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js';
// import wx from '../../assets/js/util/weixin-js-sdk';
// import wx from 'weixin-js-sdk'

export default {
    data(){
        return{
            title:this.$route.meta.title,
            direction:false,
            val:0,
            list:[
                {
                    leftText:'充50个太阳',
                    rightVal:'50元',
                },
                {
                    leftText:'充100个太阳',
                    rightVal:'100元',
                },
                {
                    leftText:'充200个太阳',
                    rightVal:'200元',
                },
                {
                    leftText:'充',
                    inputVal:true,
                    rightVal:'0元',
                }
            ],
            showHeader:true
        }
    },
    components:{
        vHeader
    },
    created:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
    methods:{
        getEgg(idx){
           let index = idx
           let val = this.val
           console.log(val);
           if(val == ''){
            val = 0
           }
           this.$refs.num[index].innerText = val+'元'
        },
        async pay(){
            let isWeiXin = !this.showHeader
            let price = Number(this.val)
            if(this.$store.state.common.loginInfo.access_token == null){
                this.$toast('请先登录')
                return;
            }
            console.log(wx);
            if(isWeiXin){
                const res = await this.$axios({
                    method:'post',
                    url:'https://hjcapi.whdatas.com/usercenter/wx-v3-pay/one',
                    data:{
                        appId:"webhaojingchouh5",
                        bodyContent: "付款阅读单品",
                        artId:'3',
                        openid: "ofyhN4ym3rpDz13N8YZslORBuOeE",
                        payId: 3,
                        price,
                        titles: "付款阅读",
                        transactionType: "JSAPI"
                    },
                    headers: { access_token: this.$store.state.common.loginInfo.access_token }
                })
                if(res.data && res.data.statusCode == 200){
                    let orderInfo =  res.data.orderInfo;
                    wx.requestPayment({
                        timeStamp:orderInfo.timeStamp,
                        nonceStr:orderInfo.nonceStr,
                        package:orderInfo.package,
                        signType:orderInfo.signType,
                        paySign:orderInfo.paySign,
                        success:res=>{
                            console.log(res);
                        }
                    })
                    // url = res.data.htmlResult
                    // url = url.match(/(\https.*)(?=\&redirect)/g)
                    // window.location.href = url
                }else{
                    console.log(res.data.responseDescription);
                }
            }else{
              let args = {
                appId:"webhaojingchouh5",
                bodyContent: "付款阅读单品",
                //ofyhN4ym3rpDz13N8YZslORBuOeE
                payId: 3,
                price,
                titles: "付款阅读",
                transactionType: "H5"
              };
              const res = await this.$HTTP.post(this, 'API_PAYMENT_PRODUCT', args, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true);
                let url;
                if(res.data && res.data.statusCode == 200){
                    url = res.data.htmlResult
                //  url = url.match(/\https.*?\json/g)
                    url = url.match(/(\https.*)(?=\&redirect)/g)
                    window.location.href = url
                }else{
                    console.log(res.data.responseDescription);
                }
            }
        },
       
    }
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        min-height: 100%;
        position: absolute;
        top: 0;
        background-color: #eee;
        .paylist{
            width: 100%;
            height: 240px;
            background-color: #fff;
            .item{
                width: 96%;
                margin: 0 auto;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                border-bottom: 1px solid #ccc;
                &:last-of-type{
                    border-bottom: none;
                }
                span{
                    display: inline-block;
                    padding-right: 6px;
                }
                .left{
                    vertical-align: middle;
                    img{
                        vertical-align: middle;
                        width: 28px;
                        height: 28px;
                    }
                    input{
                        vertical-align: middle;
                        border: none;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                    }
                }
                .right{
                    button{
                        background-color: #f4ea2a;
                        color: #fff;
                        border: none;
                        border-radius: 4px;
                    }
                    
                }
            }
        }
        .proxy{
            input{
                vertical-align: middle;
                width: 14px;
                height: 14px;
            }
            span{
                vertical-align: middle;
                font-size: 12px;
                color: #aaa;
                .gotoProxy{
                    color: #000;
                    display: inline-block;
                    
                }
            }
        }
        .present{
            width: 100%;
            p{
                margin: 20px auto;
                font-size: 14px;
                width: 60%;
                color: rgb(195, 61, 61);
                text-align: center;
                position: relative;
                span{
                    position: absolute;
                    top: 6px;
                    right: 30px;
                    width: 6px;
                    height: 6px;
                    border-top: 2px solid rgb(195, 61, 61);
                    border-right: 2px solid rgb(195, 61, 61);
                    transform: rotate(45deg);
                }
            }
        }
        .desc{
            font-size: 12px;
            height: 140px;
            color: #444;
            width: 94%;
            margin: 10px auto;
            p{
                padding: 0;
                margin: 0;
            }
        }
    }
</style>